import jQuery from "jquery"; const $ = jQuery;

const showExtraInformation = (name, on) => {
  var className = name + '-visible';

  if (on) {
    $('body').addClass(className);
  } else {
    $('body').removeClass(className);
  }
};

const start = () => {
  $(function () {
    $('#show_driving_times').click(function () { showExtraInformation('driving-times', this.checked); });
    $('#show_employee_no').click(function () { showExtraInformation('employee-no', this.checked); });

    showExtraInformation('driving-times', $('#show_driving_times').prop('checked'));
    showExtraInformation('employee-no', $('#show_employee_no').prop('checked'));
  });
};

export { start };
