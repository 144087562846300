import '../stylesheets/admin'

import Rails from '@rails/ujs'; Rails.start();
import * as ActiveStorage from '@rails/activestorage'; ActiveStorage.start();
import 'trix'
import '@rails/actiontext'

import 'bootstrap/dist/js/bootstrap';
import 'startbootstrap-sb-admin/js/sb-admin';
import swal from 'sweetalert2';
import parsley from 'parsleyjs';
import 'blueimp-file-upload';

import { handleAjaxSuccess } from 'admin/commons';
import * as BreakTimes from 'admin/break_times'; BreakTimes.start();
import * as DrivingMetrics from 'admin/driving_metrics'; DrivingMetrics.start();
import * as Banners from 'admin/banners'; Banners.start();
import * as HomeModules from 'admin/home_modules'; HomeModules.start();

//
window.handleAjaxSuccess = handleAjaxSuccess;


$(function () {
  $('#sidenavToggler').click(function () {
    $('body').toggleClass('sidenav-toggled');
  });
});

$(function () {
  $('form.ajax').on('ajax:success', handleAjaxSuccess);

  $('a.delete_point_formula[data-remote]').on("ajax:success", handleAjaxSuccess);
  $('a.delete_score_formula[data-remote]').on("ajax:success", handleAjaxSuccess);
  $('a.delete_slogan[data-remote]').on("ajax:success", handleAjaxSuccess);

  $('a[data-remote][data-handler=default]').on('ajax:success', handleAjaxSuccess);


  parsley.addValidator('employeeNo', {
    requirementType: 'string',
    validateString (value, requirement) {
      return value.match(/^[0-9]{6}$/) ? true : false
    },
    messages: {
      ko: '001234 와 같이 6자리 숫자 형식을 사용해야 합니다.'
    }
  });

  parsley.addValidator('driverPassword', {
    requirementType: 'string',
    validateString (value, requirement) {
      return value.match(/^[0-9]{4}$/) ? true : false
    },
    messages: {
      ko: '1234 와 같이 4자리 숫자 형식을 사용해야 합니다.'
    }
  });

  $(document.body).on('ajax:error', function (event) {
    let [result, status, xhr] = event.detail;
    let message = result ? result.message : '알수 없는 문제가 발생했습니다.'
    swal({
      type: 'error',
      text: (message || '')
    })
  });


  $('#upload_drivers_form')
    .on("ajax:success", function(event) {
      let [result, status, xhr] = event.detail;
      if (result.code == 0) {
        if (result.data.upserted_count > 0) {
          alert(`${result.data.upserted_count} 명의 정보를 갱신했습니다.`);
        } else {
          alert(`변경내용을 반영했습니다.`);
        }
      } else {
        alert("오류: " + result.message);
      }
    });


  $('form#new_driver')
    .on("ajax:success", function (event) {
      swal('드라이버를 추가했습니다.').then( result => location.reload() )
    });
  $('form#new_driver').parsley({
    errorClass: 'is-invalid',
    successClass: 'is-valid',
    errorsWrapper: '',
    errorsTemplate: '',
    trigger: 'change'
  });


  $('#driver_list').delegate('.edit_driver_button', 'click', function (e) {
    var driver = $(e.currentTarget).data('driver')
    var form = $('#edit_driver')
    form.attr('action', driver.path)
    form.find('#driver_id').val(driver.id)
    form.find('#driver_name').val(driver.name)
    form.find('#driver_phone_number').val(driver.phone_number)
    form.find('#driver_employee_no').val(driver.employee_no)
    form.find('#driver_team_id').val(driver.team_id)

    $('#driver_edit_form_modal').modal()
  });

  $('#driver_edit_form_modal #submit').click(function () {
    Rails.fire($('#edit_driver')[0], 'submit')
  });

  $('form#edit_driver').parsley({
    errorClass: 'is-invalid',
    successClass: 'is-valid',
    errorsWrapper: '',
    errorsTemplate: '',
    trigger: 'change'
  });

  $('form#edit_driver')
    .on("ajax:success", function (event) {
      swal('드라이버 정보를 수정했습니다.').then( result => location.reload() )
    });



  $('form#new_team')
    .on("ajax:success", function (event) {
      swal('팀을 추가했습니다.').then( result => location.reload() )
    });

  $('form#new_team').parsley({
    errorClass: 'is-invalid',
    successClass: 'is-valid',
    errorsWrapper: '',
    errorsTemplate: '',
    trigger: 'change'
  });



  $('form#driving_metric_file_upload_form')
    .on("ajax:success", handleAjaxSuccess)

  $('form#driving_metric_file_upload_form').parsley({
    errorClass: 'is-invalid',
    successClass: 'is-valid',
    errorsWrapper: '',
    errorsTemplate: '',
    trigger: 'change'
  });



  /*
  $('form.new_notice, form.edit_notice').parsley({
    errorClass: 'is-invalid',
    successClass: 'is-valid',
    errorsWrapper: '',
    errorsTemplate: '',
    trigger: 'change'
  });
  $('form.new_notice, form.edit_notice')
    .on("ajax:success", function (event) {
      location.href = '/notices'
    });
    */


  $('form.new_score_formula, form.edit_score_formula').parsley({
    errorClass: 'is-invalid',
    successClass: 'is-valid',
    errorsWrapper: '',
    errorsTemplate: '',
    trigger: 'change'
  });
  $('form.new_score_formula, form.edit_score_formula').on("ajax:success", handleAjaxSuccess);
    

  $('form.new_point_formula, form.edit_point_formula').parsley({
    errorClass: 'is-invalid',
    successClass: 'is-valid',
    errorsWrapper: '',
    errorsTemplate: '',
    trigger: 'change'
  });
  $('form.new_point_formula, form.edit_point_formula').on("ajax:success", handleAjaxSuccess);
    


  $('form.new_push_message').on('ajax:success', handleAjaxSuccess);


  const taskMonitor = function (taskUrl, interval = 5*1000) {
    const $ui = $('#taskProgress');

    const isCompleted = $ui[0] == null;
    if (isCompleted) {
      return
    }

    $.ajax({
      url: taskUrl, 
      dataType: 'json'
    }).done(function (data) {
      const { task } = data;
      if (data.task.status === 'succeeded') {
        swal('작업이 완료되었습니다.').then(function () { 
          if (data.download_url) {
            location.href = data.download_url;
          } else {
            location.reload(); 
          }
        });
      }
      else if (data.task.status === 'failed') {
        swal({
          type: 'error', 
          text: '오류가 발생하여 작업을 완료하지 못했습니다.'
        }).then(function () { location.reload(); });
      }
      else {
        if (data.task.progress > 0) {
          $ui.find('.progress-bar').width(data.task.progress + '%')
        }
        setTimeout(function () { taskMonitor(taskUrl, interval) }, interval)
      }
    }).fail(function (xhr) {
      setTimeout(function () { taskMonitor(taskUrl, interval) }, interval)
    });
  };


  $('form.new_task')
    .on('direct-uploads:start', function (event) {
      var content = 
        `<div>
          <p>파일을 업로드 하는 중 입니다....</p>
          <div class="progress">
            <div id="fileUploadProgressBar" class="progress-bar progress-bar-striped" role="progressbar" style="width: 0%" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
          </div>
        </div>`;

      swal({
        html: content,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
    })
    .on('direct-upload:progress', function (event) { 
      const { id, progress } = event.detail;
      $('#fileUploadProgressBar').width(progress + '%')
    })
    .on('ajax:success', function (event) {
      const [ data ] = event.detail;
      const taskUrl = data.url;

      //
      const content = `
        <div id="taskProgress">
          <p>업로드된 파일을 처리하고 있습니다.</p>
          <p>처리결과는 이 페이지를 벗어나더라도 아래 링크를 통해 확인할 수 있습니다.</p>
          <p><a href="${taskUrl}" target="_blank">${taskUrl}</a></p>
          <div class="progress">
            <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style="width: 100%"></div>
          </div>
        </div>
      `;
      swal({
        html: content,
        showConfirmButton: false,
        showCancelButton: true,
        cancelButtonText: '나중에 확인하기',
        focusCancel: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });

      taskMonitor(taskUrl);
    })
    .on('ajax:error', function (event) {
      swal({type: 'error', text: '오류가 발생했습니다.'});
    })


  $('a#export_driving_habits').on('ajax:success', function (event) {
    const [ data ] = event.detail;
    const taskUrl = data.url;

    const content = `
      <div id="taskProgress">
        <p>다운로드할 파일을 만들고 있습니다.<br>잠시만 기다려 주세요.</p>
        <p>이 페이지를 벗어나더라도 아래 링크를 통해서 만들어진 파일을 내려받을 수 있습니다.</p>
        <p><a href="${taskUrl}" target="_blank">${taskUrl}</a></p>
        <div class="progress">
          <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style="width: 100%"></div>
        </div>
      </div>
      `;
      swal({
        html: content,
        showConfirmButton: false,
        showCancelButton: true,
        cancelButtonText: '나중에 확인하기',
        focusCancel: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });

      taskMonitor(taskUrl);
  })
  .on('ajax:error', function (event) {
    swal({type: 'error', text: '오류가 발생했습니다.'});
  })

  $('a#export_break_times').on('ajax:success', function (event) {
    const [ data ] = event.detail;
    const taskUrl = data.url;

    const content = `
      <div id="taskProgress">
        <p>다운로드할 파일을 만들고 있습니다.<br>잠시만 기다려 주세요.</p>
        <p>이 페이지를 벗어나더라도 아래 링크를 통해서 만들어진 파일을 내려받을 수 있습니다.</p>
        <p><a href="${taskUrl}" target="_blank">${taskUrl}</a></p>
        <div class="progress">
          <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style="width: 100%"></div>
        </div>
      </div>
      `;
      swal({
        html: content,
        showConfirmButton: false,
        showCancelButton: true,
        cancelButtonText: '나중에 확인하기',
        focusCancel: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });

      taskMonitor(taskUrl);
  })
  .on('ajax:error', function (event) {
    swal({type: 'error', text: '오류가 발생했습니다.'});
  })

  $('#upload_drivers_form')
    .on("ajax:success", function(event) {
      let [result, status, xhr] = event.detail;
      if (result.code == 0) {
        if (result.data.upserted_count > 0) {
          alert(`${result.data.upserted_count} 명의 정보를 갱신했습니다.`);
        } else {
          alert(`변경내용을 반영했습니다.`);
        }
      } else {
        alert("오류: " + result.message);
      }
    });
});
