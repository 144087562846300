import jQuery from "jquery"; const $ = jQuery;
import "jquery-ui/ui/widgets/sortable";
import { handleAjaxSuccess } from "admin/commons";

const toggleFields = (module_type) => {
  $('form.home_module .dependant-on').hide();
  $('form.home_module .dependant-on.' + module_type).show();
}

const start = () => {
  $(function () {
    $('#home_module_list tbody').sortable({
      cursor: "move",
      handle: ".drag_handle",
      containment: "#home_module_list",
      start: function (event, ui) {
        const w = $(event.target).width();
        $(ui.helper).css('width', `${w}px`);

        // console.log(w);
        // console.log($(ui.helper).css('width'));
        // console.log($(ui.helper).parent().html());
      },
      stop: function () {
        $('#save_ordinals').removeAttr("disabled");
      }
    });

    
    $('form.home_module select.module_type').change(function () {
      toggleFields(this.value);
    });
    toggleFields($('form.home_module select.module_type').val());
  });
};

export { start };
