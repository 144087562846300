import jQuery from "jquery"; const $ = jQuery;
import { DirectUpload } from "@rails/activestorage"
import { handleAjaxSuccess } from "admin/commons";

const preview = (input_field, image_tag) => {
  var file = input_field.files && input_field.files[0];
  if (!file || !image_tag) {
    return;
  }

  var reader = new FileReader();
  reader.onload = function (e) {
    const { result } = e.target;
    $(image_tag).attr("src", result);
  }
  reader.readAsDataURL(file);
}

const preview_video = (input_field, $video_tag) => {
  var file = input_field.files && input_field.files[0];
  if (!file)      return;
  if (!$video_tag) return;

  $video_tag.find("source").eq(0).attr("src", URL.createObjectURL(file));  
  $video_tag[0].load();
}

const Youtube = {
  getVideoId: (url) => {
    // https://youtu.be/HaPQznTwD4E
    // <iframe width="560" height="315" src="https://www.youtube.com/embed/HaPQznTwD4E" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

    if (url.startsWith("https://youtu.be/")) {
      const u = new URL(url);
      return u.pathname.replace(/^\//, '');
    } else if (url.startsWith("https://www.youtube.com/embed/")) {
      const u = new URL(url);
      return u.pathname.replace(/^\/embed\//, '');
    } else {
      return null;
    }
  },

  getEmbedUrl: (url) => {
    const videoId = Youtube.getVideoId(url)
    if (videoId) {
      return "https://www.youtube.com/embed/" + videoId;
    } else {
      return url;
    }
  },

  isValidUrl: (url) => {
    try {
      const host = new URL(url).host;
      return host === 'www.youtube.com' ||
        host === 'youtube.com' ||
        host == 'youtu.be';
    } catch (e) {
      return false;
    }
  }
}

const previewYoutubeVideo = (url, $youtube_preview_tag) => {
  if (Youtube.isValidUrl(url)) {
    $youtube_preview_tag.html('<iframe width="560" height="315" src="'+ url +'" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>');
  } else {
    $youtube_preview_tag.html('');
  }
}

const start = () => {
  $(function () {
    $('form.banner').on('ajax:success', handleAjaxSuccess);

    $('form.banner select.banner-type').change(function (e) {
      location.href = "?type=" + $(this).val();
    });

    $('#image_banner_image').change(function (e) {
      preview(this, $("#banner_image_preview"));
    });

    $("#video_banner_cover_image").change(function (e) {
      preview(this, $("#cover_image_preview"));
    });

    $("#video_banner_video").change(function (e) {
      preview_video(this, $("#video_preview"));
    });


    const $youtubeUrl = $("#youtube_banner_youtube_url");
    $youtubeUrl.change(function (e) {
      const url = this.value;
      const embedUrl = Youtube.getEmbedUrl(url);
      this.value = embedUrl;

      previewYoutubeVideo(embedUrl, $("#youtube_preview"));
    });
  });
}

export { start };

