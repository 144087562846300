import swal from 'sweetalert2';

const handleAjaxSuccess = (event) => {
  let [result, status, xhr] = event.detail;

  var goto_redirect_url = function () {
    if (result.reload) {
      location.reload();
    } else if (result.redirect_url) {
      location.href = result.redirect_url
    }
  }

  if (result.message) {
    swal(result.message).then(goto_redirect_url)
  } else {
    goto_redirect_url()
  }
}

export { handleAjaxSuccess };
